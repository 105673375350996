<template>
    <main>
        <Button icon="md-refresh" shape="circle" @click="loadData">刷新</Button>

        <Divider />

        <Form ref="form" label-position="top">
            <Row>
                <Col :span="12" :offset="3">
                    <FormItem v-for="item in form" :key="item.id">
                        <p slot="label">
                            <span>{{ item.label }}</span>
                            <span class="ml-xs text-xs text-grey">[ {{ item.key }} ]</span>
                        </p>
                        <Checkbox v-model="item.value" style="margin-top:0.38px" size="large" v-if="item.type == 'boolean'"/>
                        <InputNumber v-model="item.value" v-else-if="item.type == 'integer'"/>
                        <InputNumber v-model="item.value" :step="0.01" v-else-if="item.type == 'double'"/>
                        <TimePicker v-model="item.value" type="time" format="HH:mm" :steps="[0, 30]" v-else-if="item.type == 'time'"></TimePicker>
                        <Input v-model="item.value" v-else/>
                        <p class="text-sm text-grey">{{ item.desc }}</p>
                    </FormItem>
                </Col>
            </Row>

            <Divider />
            <Row v-if="Auth([ 'Config', 'update' ])">
                <Col :span="9" :offset="3">
                    <FormItem>
                        <Button type="primary" icon="md-filing"  @click="submit">保存修改</Button>
                    </FormItem>
                </Col>
            </Row>
        </Form>
        
    </main>
</template>

<script>
    export default {
        data()
        {
            return {
               
                form: null
            }
        },
        mounted()
        {
            this.loadData()
        },
        methods: {
            loadData()
            {
                this.$http.get('/config/list')
                    .then(res => {
                        this.form = res
                    })
            },
            submit()
            {
                this.$Modal.confirm({
                    title : '确认保存修改吗？',
                    onOk  : () => {
                        this.$http.post('/config/save', this.form)
                            .then(res => {
                                this.loadData()
                            })
                    }
                })
            },
            
        }
    }
</script>